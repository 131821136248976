/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
// import scrollScreen from 'rc-scroll-anim/lib/ScrollScreen';
import Nav2 from './Nav2';
import Banner2 from './Banner2';
import Content3 from './Content3';
import Content4 from './Content4';
// import Banner5 from './Banner5';
import Banner6 from './Banner6';
// import Banner7 from './Banner7';
import Teams1 from './Teams1';
import Contact0 from './Contact0';
import Footer2 from './Footer2';
import Point from './Point';
import About from './About';
// import QQ from './contact-qq';
import {
  Nav20DataSource,
  Banner20DataSource,
  Content30DataSource,
  Teams10DataSource,
  Contact00DataSource,
  Content40DataSource,
  // Banner50DataSource,
  Banner60DataSource,
  // Banner70DataSource,
  Footer20DataSource,
  AboutDataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
        // 实现整屏滚动
        // scrollScreen.init({
        //   location: [
        //     'Banner2_0',
        //     'Content3_0',
        //     'Content4_0',
        //     'Banner6_0',
        //   ],
        // });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Nav2 id="Nav2_0" key="Nav2_0" dataSource={Nav20DataSource} isMobile={this.state.isMobile} />,
      <Banner2
        id="home"
        key="home"
        dataSource={Banner20DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content3
        id="product"
        key="product"
        dataSource={Content30DataSource}
        isMobile={this.state.isMobile}
      />,
      !isMobile && (
        <Banner6
          id="about"
          key="about"
          dataSource={Banner60DataSource}
          isMobile={this.state.isMobile}
        />
      ),
      isMobile && (
        <About id="about" key="about" dataSource={AboutDataSource} isMobile={this.state.isMobile} />
      ),
      <Content4
        id="business"
        key="business"
        dataSource={Content40DataSource}
        isMobile={this.state.isMobile}
      />,
      <Teams1
        id="contact"
        key="contact"
        dataSource={Teams10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Contact0
        id="map"
        key="map"
        dataSource={Contact00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer2
        id="footer"
        key="footer"
        dataSource={Footer20DataSource}
        isMobile={this.state.isMobile}
      />, // 导航和页尾不进入锚点区，如果需要，自行添加;
      <Point
        key="list"
        data={[['home','首页'], ['product','辣创产品'], ['about','关于辣创'], ['business','主营业务'], ['contact','联系我们']]}
        type="point-rect"
        size="point-large"
      />,
      // <QQ />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
