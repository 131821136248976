import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import { getChildrenToRender } from './utils';

class Banner6 extends React.PureComponent {
  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.childWrapper}
            componentProps={{
              md: dataSource.childWrapper.md,
              xs: dataSource.childWrapper.xs,
            }}
          >
            {dataSource.childWrapper.children.map(getChildrenToRender)}
            <Row {...dataSource.content}>
              {dataSource.content.children.map((item, index) => (
                <Col lg={4} md={4} xs={4} {...item}>
                  <img alt="" src={item.image} />
                </Col>
              ))}
            </Row>
          </QueueAnim>
          <TweenOne animation={animType.one} key="title" {...dataSource.text}>
            <div className="box">
              <div className="box-text">
                {dataSource.text.children.map((item, i) => {
                  return <div {...item}>{item.children}</div>;
                })}
              </div>
            </div>
          </TweenOne>
        </div>
      </div>
    );
  }
}
export default Banner6;
