import React from 'react';
import TweenOne from 'rc-tween-one';
import { Modal, Row, Col } from 'antd';

class Business extends React.PureComponent {
  render() {
    const { visible, handleCancel, item, isMobile } = this.props;
    if (!item.title) return null;
    return (
      <>
        <Modal
          width="1200px"
          height={isMobile ? '' : '80vh'}
          visible={visible}
          onCancel={handleCancel}
          footer={
            <Row className="business-modal-tel-row">
              <Col xs={24} md={24}>
                <div className="business-modal-tel">
                  <div className="business-modal-tel-img">&nbsp;</div>
                  <span className="business-modal-tel-text">联系我们：</span>
                  <span className="business-modal-tel-number"><a href="tel:023-85562510">023-85562510</a></span>
                </div>
              </Col>
            </Row>
          }
          className="modal-background"
          align
        >
          <div {...item.textWrapper}>
            <TweenOne key="h2" component="h2" {...item.title}>
              {item.title.children}
            </TweenOne>
          </div>
          <Row className="business-modal-pic-row" align="center">
            {item.content.children.map((child, index) => (
              <Col xs={24} md={1} {...child} key={index}>
                <TweenOne
                  className="business-modal-pic-list-item"
                  animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
                >
                  <span
                    className={
                      index === item.content.children.length - 1 ? 'business-li-end' : 'business-li'
                    }
                  ></span>
                  <img width="100%" src={child.images} alt="img" />
                  <TweenOne key="h2" component="h2">
                    {child.title}
                  </TweenOne>
                </TweenOne>
              </Col>
            ))}
          </Row>
        </Modal>
      </>
    );
  }
}

export default Business;
