import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
// import Product from './product';
// import { getChildrenToRender } from './utils';

class Content3 extends React.PureComponent {
  getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;
  state = { visible: false, item: {} };

  showModal = (item) => {
    if (item.content.children.length === 0) return;
    this.setState({
      visible: true,
      item,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    // const { visible, item } = this.state;
    delete props.dataSource;
    delete props.isMobile;
    let clearFloatNum = 0;
    const children = dataSource.block.children.map((item, i) => {
      const childObj = item.children;
      const delay = isMobile ? i * 50 : this.getDelay(i, 24 / item.md);
      const liAnim = {
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
        delay,
      };
      const childrenAnim = { ...liAnim, x: '+=10', delay: delay + 100 };
      clearFloatNum += item.md;
      clearFloatNum = clearFloatNum > 24 ? 0 : clearFloatNum;
      return (
        <TweenOne
          component={Col}
          animation={liAnim}
          key={item.name}
          {...(isMobile ? null : item)}
          componentProps={{ md: item.md, xs: item.xs }}
          className={!clearFloatNum ? `${item.className || ''} clear-both`.trim() : item.className}
        >
          <TweenOne
            animation={{
              x: '-=10',
              opacity: 0,
              type: 'from',
              ease: 'easeOutQuad',
            }}
            key="img"
            {...childObj.icon}
          >
            {childObj.title.children ? (
              // eslint-disable-next-line
              <a onClick={() => this.showModal(item.children)}>
                {childObj.icon.children && (
                  <img src={childObj.icon.children} width="100%" alt="img" />
                )}
                <TweenOne key="h2" animation={childrenAnim} component="h2" {...childObj.title}>
                  {childObj.title.children}
                </TweenOne>
                {childObj.desc && (
                  <TweenOne key="h3" animation={childrenAnim} component="h3" {...childObj.desc}>
                    {childObj.desc.children}
                  </TweenOne>
                )}
                {/* {childObj.title.children && (
                  <Button className="btn-more" size="small">
                    查看详情
                  </Button>
                )} */}
              </a>
            ) : (
              !isMobile && (
                <>
                  {childObj.icon.children && (
                    <img src={childObj.icon.children} width="100%" alt="img" />
                  )}
                  <TweenOne key="h2" animation={childrenAnim} component="h2" {...childObj.title}>
                    {childObj.title.children}
                  </TweenOne>
                  {childObj.desc && (
                    <TweenOne key="h2" animation={childrenAnim} component="h2" {...childObj.desc}>
                      {childObj.desc.children}
                    </TweenOne>
                  )}
                </>
              )
            )}
          </TweenOne>
        </TweenOne>
      );
    });
    const logo = dataSource.block.children.map((item, i) => {
      const childObj = item.children;
      const delay = isMobile ? i * 50 : this.getDelay(i, 24 / item.md);
      const liAnim = {
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
        delay,
      };
      const childrenAnim = { ...liAnim, x: '+=10', delay: delay + 100 };
      clearFloatNum += item.md;
      clearFloatNum = clearFloatNum > 24 ? 0 : clearFloatNum;
      return (
        !childObj.title.children &&
        isMobile && (
          <TweenOne
            component={Col}
            animation={liAnim}
            key={item.name}
            {...(isMobile ? null : item)}
            componentProps={{ md: item.md, xs: item.xs }}
            className={
              !clearFloatNum ? `${item.className || ''} clear-both`.trim() : item.className
            }
          >
            <TweenOne
              animation={{
                x: '-=10',
                opacity: 0,
                type: 'from',
                ease: 'easeOutQuad',
              }}
              key="img"
              {...childObj.icon}
            >
              <>
                {childObj.icon.children && (
                  <img src={childObj.icon.children} width="100%" alt="img" />
                )}
                <TweenOne key="h2" animation={childrenAnim} component="h2" {...childObj.title}>
                  {childObj.title.children}
                </TweenOne>
                {childObj.desc && (
                  <TweenOne key="h2" animation={childrenAnim} component="h2" {...childObj.desc}>
                    {childObj.desc.children}
                  </TweenOne>
                )}
              </>
            </TweenOne>
          </TweenOne>
        )
      );
    });
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <OverPack {...dataSource.OverPack}>
            <QueueAnim key="u" type="bottom">
              <Row key="row" {...dataSource.block}>
                {logo}
                {children}
              </Row>
            </QueueAnim>
          </OverPack>
        </div>
        {/* <Product visible={visible} item={item} handleCancel={this.handleCancel} /> */}
      </div>
    );
  }
}

export default Content3;
