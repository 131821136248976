import React from 'react';
import { Row, Col } from 'antd';
import TweenOne from 'rc-tween-one';

class About extends React.PureComponent {
  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <TweenOne animation={animType.one} key="title" {...dataSource.image}>
            <div className="about-box"></div>
            <img className="about-box-image" src={'images/about-2.jpg'} width="100%" alt="img" />
            <div className="about-box-text">
              <div className="about-title">
                <div className="about-title-text">关于辣创</div>
                <div className="about-desc-text">ABOUT US</div>
                <div className="about-name-text">重庆辣创味业</div>
              </div>
              {dataSource.text.children.map((item, i) => {
                return (
                  <div {...dataSource.text} key={i}>
                    <div {...item}>
                      <span>{item.children}</span>
                    </div>
                  </div>
                );
              })}
              <Row className="about-slogan">
                <Col xs={6} md={6} className="about-slogan-left"></Col>
                <Col xs={12} md={12} className="about-slogan-text">
                  志存高远，坚韧踏实
                </Col>
                <Col xs={6} md={6} className="about-slogan-right"></Col>
              </Row>
              <Row>
                {dataSource.images.map((item, i) => {
                  return (
                    <Col xs={8} md={8} key={i}>
                      <img className="about-images" src={item} width="100%" alt="img" />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </TweenOne>
        </div>
      </div>
    );
  }
}
export default About;
