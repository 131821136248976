import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';
import Business from './business';

class Content4 extends React.PureComponent {
  getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;
  state = { visible: false, item: {} };

  showModal = (item) => {
    if (item.content.children.length === 0) return;
    this.setState({
      visible: true,
      item,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    const { visible, item } = this.state;
    delete props.dataSource;
    delete props.isMobile;
    let clearFloatNum = 0;
    const children = dataSource.block.children.map((item, i) => {
      const childObj = item.children;
      const delay = isMobile ? i * 50 : this.getDelay(i, 24 / item.md);
      const liAnim = {
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
        delay,
      };
      const childrenAnim = { ...liAnim, x: '+=10', delay: delay + 100 };
      clearFloatNum += item.md;
      clearFloatNum = clearFloatNum > 24 ? 0 : clearFloatNum;
      return (
        <TweenOne
          component={Col}
          animation={liAnim}
          key={item.name}
          {...item}
          componentProps={{ md: item.md, xs: item.xs }}
          className={!clearFloatNum ? `${item.className || ''} clear-both`.trim() : item.className}
        >
          <div {...childObj.link}>
            {
              // eslint-disable-next-line
              <a onClick={() => this.showModal(item.children)}>
                <div {...childObj.textWrapper}>
                  <TweenOne key="h2" animation={childrenAnim} component="h2" {...childObj.title}>
                    {childObj.title.children}
                  </TweenOne>
                </div>
                <TweenOne
                  animation={{
                    x: '-=10',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                  }}
                  key="img"
                  {...childObj.icon}
                >
                  <img src={childObj.icon.children} width="100%" alt="img" />
                </TweenOne>
              </a>
            }
          </div>
        </TweenOne>
      );
    });
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          <OverPack {...dataSource.OverPack}>
            <QueueAnim key="u" type="bottom">
              <Row key="row" {...dataSource.block}>
                {children}
              </Row>
            </QueueAnim>
          </OverPack>
        </div>
        <Business
          visible={visible}
          isMobile={isMobile}
          item={item}
          handleCancel={this.handleCancel}
        />
      </div>
    );
  }
}

export default Content4;
